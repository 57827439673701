import React, { Fragment } from "react";
import DevelopedBy from "./DevelopedBy/DevelopedBy";
import "./Footer.css";
import ScrollService from "../../utilities/ScrollService";
export default function Footer() {
  return (
    <Fragment>
    <div className="scroll-container">
      <button
        className="btn-scroll"
        onClick={() => ScrollService.scrollHandler.scrollToHome()}
      >
        {" "}
        <i className="fa fa-arrow-up"></i>
      </button>
    </div>
    <DevelopedBy name="Rajitha Rajasooriya"/>
    </Fragment>
  );
}
