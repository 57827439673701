import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Testimonial.css";
import lady from "../../../src/img/Testimonial/lady.png";
import mike from "../../../src/img/Testimonial/mike.png";
import man from "../../../src/img/Testimonial/man.png";
import shape from "../../../src/img/Testimonial/shape-bg.png";

export default function Testimonial(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const options = {
    loop: true,
    margin: 0,
    nav: true,
    animateIn: "bounceInRight",
    animateOut: "bounceOutRight",
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <div>
      <ScreenHeading
        title={"Testimonial"}
        subHeading={"some recommendations I have received through LinkedIn"}
      />
      <p className="link-to-linkedin">
        <a
          href="https://www.linkedin.com/in/rajitharajasooriya/"
          className="linked-in-anchor"
        >
          Click here to see all the recommendations received in LinkedIn{" "}
        </a>
      </p>
      <section className="testimonial-section fade-in" id={props.id || ""}>
        <div className="container">
          <div className="row">
            <OwlCarousel
              className="owl-carousel"
              id="testimonial-carousel"
              {...options}
            >
              <div className="col-lg-12">
                <div className="testi-item">
                  <div className="testi-comment">
                    <p>
                      <i className="fa fa-quote-left" />
                      Rajitha was a great employee to work with. We worked
                      together at Syscolabs for almost 1 year and he helped me a
                      lot to get started and also to understand internal
                      processes when I was new to the team. Also, he knows how
                      to help his teammates and get the best out of them. He is
                      a nice team player who can so easy to work together with.
                      He has a good understanding NodeJs, ReactJs, and Ember.
                      Thanks a lot, Rajitha! You made the transition relatively
                      smooth for me and were very helpful.”.
                      <i className="fa fa-quote-right" />
                    </p>
                    <ul className="stars list-unstyled">
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star-half-alt" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="testi-item">
                  <div className="testi-comment">
                    <p>
                      <i className="fa fa-quote-left" />
                      I can assure that Rajitha is a highly skilled engineer.
                      He's comfortable with learning new arenas where I
                      personally witnessed that his transition to The Pulse team
                      by quickly learning the relevant technologies and
                      frameworks. He's highly proficient and experienced with
                      NodeJs, ReactJs and EmberJs. In fact, I learnt emberJs for
                      a later project from him. He has great leadership skills
                      as he handled tough situations pretty well during his
                      tenure in the CakeShop team handling frequent releases.
                      <i className="fa fa-quote-right" />
                    </p>
                    <ul className="stars list-unstyled">
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star-half-alt" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="testi-item">
                  <div className="testi-comment">
                    <p>
                      <i className="fa fa-quote-left" />
                      Rajitha worked with me during my time as the Team Lead of
                      the Operations Engineering team at Sysco LABS. As a
                      Software Engineer, I found him to be an energetic and
                      capable engineer. The immense support he gave in
                      delivering products and artifacts on time with higher
                      quality is admirable. I also identified Rajitha as a great
                      team player who won a lot of hearts and minds. I enjoyed
                      working with him and any team is lucky to have him.
                      <i className="fa fa-quote-right" />
                    </p>
                    <ul className="stars list-unstyled">
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star-half-alt" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="testi-item">
                  <div className="testi-comment">
                    <p>
                      <i className="fa fa-quote-left" />
                      Rajitha is a highly skilled engineer. He has the passion
                      and dedication to achieve his goals in his career. I was
                      able to work with him on different projects. There are few
                      situations where Rajitha had to work extra hours and he
                      took everything very easy and delivered the tasks on time
                      with a high qulity. Rajitha will be a great addition to
                      the team/company.
                      <i className="fa fa-quote-right" />
                    </p>
                    <ul className="stars list-unstyled">
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star-half-alt" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="testi-item">
                  <div className="testi-comment">
                    <p>
                      <i className="fa fa-quote-left" />
                      Rajitha worked with me during my time as the Team Lead.
                      Rajitha is a committed individual who always strives to
                      learn and apply new skills. I was able to witness him
                      transitioning from the university academic background to
                      professional software Engineering on the side of several
                      areas like programing concepts, best practices, and other
                      engineering skills. He is keen on both front-end(React &
                      NodeJs) and back-end technologies(Java Spring, python)
                      with very good programming literacy while maintaining
                      proper programming concepts and practices. As a team
                      member, he played an active role that helps me a lot to
                      smoothly manage the team.
                      <i className="fa fa-quote-right" />
                    </p>
                    <ul className="stars list-unstyled">
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star-half-alt" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="testi-item">
                  <div className="testi-comment">
                    <p>
                      <i className="fa fa-quote-left" />
                      Rajitha, a sharp professional with a great attitude,
                      joined Railsbank to work with us to deliver a new project
                      which was inherently complex, and he adapted to the
                      technical stack and deployment strategies fluently. Being
                      a good team player, he communicated across teams to ensure
                      the new project was in line with the existing standards in
                      the company. After joining, he had a running start and
                      contributed to the project within the first week. Before
                      engaging in any design/development, he preferred to get to
                      know the underlying system and its surroundings better,
                      and because of this, he was able to deliver reliably and
                      faster. I'd be quite happy to be working with him again in
                      the future.
                      <i className="fa fa-quote-right" />
                    </p>
                    <ul className="stars list-unstyled">
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star-half-alt" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
      <div className="footer-image">
        <img src={shape} alt="Phot0 not responding" />
      </div>
    </div>
  );
}
