import React, { useState, useEffect } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Resume.css";

const Resume = (props) => {
  /* STATES */
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carousalOffsetStyle, setCarousalOffsetStyle] = useState({});

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;

    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  /* REUSABLE MINOR COMPONENTS */
  const ResumeHeading = (props) => {
    return (
      <div className="resume-heading">
        <div className="resume-main-heading">
          <div className="heading-bullet"></div>
          <span>{props.heading ? props.heading : ""}</span>
          {props.fromDate && props.toDate ? (
            <div className="heading-date">
              {props.fromDate + "-" + props.toDate}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="resume-sub-heading">
          <span>{props.subHeading ? props.subHeading : ""}</span>
        </div>
        <div className="resume-heading-description">
          <span>{props.description ? props.description : ""}</span>
        </div>
      </div>
    );
  };

  /* STATIC RESUME DATA FOR THE LABELS*/
  const resumeBullets = [
    { label: "Education", logoSrc: "education.svg" },
    { label: "Work History", logoSrc: "work-history.svg" },
    { label: "Technologies", logoSrc: "programming-skills.svg" },
    // { label: "Projects", logoSrc: "projects.svg" },
    { label: "Interests", logoSrc: "interests.svg" },
  ];

  //here we have
  const programmingSkillsDetails = [
    { skill: "JavaScript", ratingPercentage: 85 },
    { skill: "Express JS", ratingPercentage: 89 },
    { skill: "Node JS", ratingPercentage: 89 },
    { skill: "React", ratingPercentage: 80 },
    { skill: "Core Java", ratingPercentage: 70 },
    { skill: "Python", ratingPercentage: 70 },
    { skill: "EmberJS", ratingPercentage: 70 },
    { skill: "HTML", ratingPercentage: 80 },
    { skill: "Terraform", ratingPercentage: 60 },
    { skill: "MongoDB", ratingPercentage: 80 },
    { skill: "MySQL", ratingPercentage: 80 },
    { skill: "GraphQL", ratingPercentage: 60 },
    { skill: "Git", ratingPercentage: 70 },
  ];

  const projectsDetails = [
    {
      title: "Cake Shop / Cake SCAT",
      duration: { fromDate: "2019", toDate: "2020" },
      description:
        "An online purchasing platform for buying POS devices. Developed both front-end and back-end features. Improved the codebase which later improved the product's performance",
      subHeading: "Technologies Used: Ember JS, NodeJS, MongoDB, Docker, AWS",
    },
    {
      title: "Pulse",
      duration: { fromDate: "2020", toDate: "2021" },
      description: "Proactive monitoring tool which has multiple API's and a Front-End and a Linux service.",
      subHeading:
        "Technologies Used:  Python, ReactJS, NodeJS/ExpressJs, MongoDB, MySQL",
    },
    {
      title: "Universal Menu",
      duration: { fromDate: "2020", toDate: "2021" },
      description:
        "A restaurant management system",
      subHeading:
        "Technologies Used: Java/Spring, GraphDB, ReactJS",
    },
    {
      title: "Compliance-AP",
      duration: { fromDate: "2021", toDate: "2022" },
      description:
        "A component to track compliance status of e-transactions",
      subHeading:
        "Technologies Used: TypeScript, AWS Lambda",
    },
    {
      title: "Dunkees API",
      duration: { fromDate: "2020", toDate: "2021" },
      description:
        "A restaurant management system",
      subHeading:
        "Technologies Used: Java/Spring, GraphDB, ReactJS",
    },
    {
      title: "Portfolio Application",
      duration: { fromDate: "2022", toDate: "Present" },
      description:
        "A portfolio websit",
      subHeading:
        "Technologies Used: ReactJS, NodeJS",
    },
  ];

  const resumeDetails = [
    <div className="resume-screen-container" key="education">
      <ResumeHeading
        heading={"University of Peradeniya, Sri Lanka"}
        subHeading={"Bachelor of the Science in Engineering"}
        fromDate={"2014"}
        toDate={"2018"}
      />

      <ResumeHeading
        heading={"National Institute of Business Management, Sri Lanka"}
        subHeading={"Certificate Course in Computer Science"}
        fromDate={"2013"}
        toDate={"2024"}
      />
      <ResumeHeading
        heading={"Government Science College, Sri Lanka"}
        subHeading={"Combined Mathematics"}
        fromDate={"2008"}
        toDate={"2011"}
      />
    </div>,

    /* WORK EXPERIENCE */
    <div className="resume-screen-container-scroll" key="work-experience">
      <div className="experience-container">
        <ResumeHeading
          heading={"EMQ"}
          subHeading={"Software Development Engineer"}
          fromDate={"2022"}
          toDate={"Present"}
        />
        <div className="experience-description">
          <span className="resume-description-text">
            - Design and develop financial services. <br/>
            - Implement REST APIs using FastAPI Python framework. <br/>
            - Use serveless technologies to provide uninterupted, secured services.
          </span>
        </div>
      </div>
      <div className="experience-container">
        <ResumeHeading
          heading={"Railsbank"}
          subHeading={"Associate Technical Lead"}
          fromDate={"2021"}
          toDate={"2022"}
        />
        <div className="experience-description">
          <span className="resume-description-text">
            - The team leader of a team of 4 members. The company provides banking services, hence engaged with
              implementing financial/banking services.<br/>
            - Implemented more than 4 Lambda functions, integrated them with AWS components, 3rd party APIs, and
              Snowflake, using TypeScript/NodeJS and created the AWS components using Terraform and deployed in AWS Lambda. <br/>
            - Designed more than 5 engineering designs for upcoming features, implemented them and deployed them in production. <br/>
            - Created a new project using Cucumber to write integration tests for newly added lambda functions.
          </span>
          <br />
        </div>
      </div>
      <div className="experience-container">
        <ResumeHeading
          heading={"Cake Engineering"}
          subHeading={"Software Engineer"}
          fromDate={"2019"}
          toDate={"2021"}
        />
        <div className="experience-description">
          <span className="resume-description-text">
            - Worked as the main contact point engineer for a critical internal product which is used to solve issues
            of our business product without having to contact the customers. <br/>
            - Completed 100% of all the work of the above product, from feature designing and development to production deployment and
            production support (front-end - ReactJS, back-end - NodeJS + Python). <br/>
            - Developed features of a Java Spring API that has a complex GraphDB database structure <br/>
            - Created a new Spring Boot API from scratch which acts as a proxy for other APIs. Created the boilerplate,
            developed the features, created the AWS resources and deployed it in AWS ECS <br/>
            - Identified and fixed a memory leak issue of an NodeJS API which later improved its performance. <br/>
            - Created an automated call generation system that is integrated with Slack using NodeJS, ReactJS and MongoDB.
          </span>
          <br />
        </div>
      </div>
      <div className="experience-container">
        <ResumeHeading
          heading={"Synopsys"}
          subHeading={"Research and Development Engineer - Intern "}
          fromDate={"2017"}
          toDate={"2018"}
        />
        <div className="experience-description">
          <span className="resume-description-text">
            - Worked as a trainee Research & Development Engineer. Implemeted an UI desktop application using QT & C++<br/>
          </span>
          <br />
        </div>
      </div>
    </div>,

    /* PROGRAMMING SKILLS */
    <div
      className="resume-screen-container programming-skills-container"
      key="programming-skills"
    >
      {programmingSkillsDetails.map((skill, index) => (
        <div className="skill-parent" key={index}>
          <div className="heading-bullet"></div>
          <span>{skill.skill}</span>
          <div className="skill-percentage">
            <div
              style={{ width: skill.ratingPercentage + "%" }}
              className="active-percentage-bar"
            ></div>
          </div>
        </div>
      ))}
    </div>,

    /* PROJECTS */
    // <div className="resume-screen-container-scroll" key="projects">
    //   {projectsDetails.map((projectsDetails, index) => (
    //     <ResumeHeading
    //       key={index}
    //       heading={projectsDetails.title}
    //       subHeading={projectsDetails.subHeading}
    //       description={projectsDetails.description}
    //       fromDate={projectsDetails.duration.fromDate}
    //       toDate={projectsDetails.duration.toDate}
    //     />
    //   ))}
    // </div>,

    /* Interests */
    <div className="resume-screen-container" key="interests">
      <ResumeHeading
        heading="Coaching and Mentoring"
        description="Apart from being a tech enthusiast, I also love to teach people what I know simply because i believe in sharing."
      />
      <ResumeHeading
        heading="Music"
        description="I frequently listen to music, which is a really good way to calm your mind, release stress and enjoy a particular moment for a while."
      />
      <ResumeHeading
        heading="Competitive Gaming"
        description="I have been playing computer games and PlayStation games for a long time. I tend to enjoy every second of it because it is fun and at the same time it is like reading a book, where you will live in an imaginary world for a period of time, which is a really different experience to have."
      />
    </div>,
  ];

  const handleCarousal = (index) => {
    let offsetHeight = 360;

    let newCarousalOffset = {
      style: { transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };

    setCarousalOffsetStyle(newCarousalOffset);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
    return resumeBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousal(index)}
        className={
          index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"
        }
        key={index}
      >
        <img
          className="bullet-logo"
          src={require(`../../assets/Resume/${bullet.logoSrc}`).default}
          alt="B"
        />
        <span className="bullet-label">{bullet.label}</span>
      </div>
    ));
  };

  const getResumeScreens = () => {
    return (
      <div
        style={carousalOffsetStyle.style}
        className="resume-details-carousal"
      >
        {resumeDetails.map((ResumeDetail) => ResumeDetail)}
      </div>
    );
  };

  useEffect(() => {
    return () => {
      /* UNSUBSCRIBE THE SUBSCRIPTIONS */
      fadeInSubscription.unsubscribe();
    };
  }, [fadeInSubscription]);

  return (
    <div
      className="resume-container"
      id={props.id || ""}
    >
      <div className="resume-content">
        <ScreenHeading title={"Resume"} subHeading={"My formal Bio Details"} />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullet-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>

          <div className="resume-bullet-details">{getResumeScreens()}</div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
