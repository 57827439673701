import React, { Fragment } from "react";
import "./DevelopedBy.css";

const DevelopedBy = (props) => {
  return (
    <div className="developed-by">
    <p>{`Developed By ${props.name}`}</p>
    </div>
  );
}

export default DevelopedBy;